@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  /*background-color: #C0D6E8;*/
  /*  background-color: #ebfff8;*/

}

*,
*::before,
*::after {
    box-sizing: border-box;
    /*font-family: "Courier Prime", serif;*/
    font-family: 'Montserrat', sans-serif; /* More character in headings */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root, .App {
    height: 100%;
    width: 100%;
}